<template>
<div class="flex flex-col transition-all select-none" :class="{
  'opacity-0' : !visible,
  'opacity-100' : visible
}">
  <div class="flex flex-col items-center justify-center rounded-full transition-all bg-slate-500" :style="pending || !visible ? '' : `background: linear-gradient(63deg, ${ BGColor() } 0%, ${ BGColor(65) } 100%);
    ${ (size == 'xs' || size == 'xss') && 'font-size: 0.55rem;' }
  `" :class="{
    'w-3 h-3 text-xs' : size == 'xxs',
    'w-6 h-6 text-xs' : size == 'xs',
    'w-8 h-8 text-xs' : size == 'sm',
    'w-10 h-10 text-sm' : size == 'md',
    'w-12 h-12 text-md' : size == 'lg',
    'w-16 h-16 text-lg' : size == 'xl',
    'w-24 h-24 text-xl' : size == '2xl',

    'animate-pulse': pending || !visible,
  }">
    <p
      class="uppercase font-bold font-poppins transition-all"
      :class="{ 'opacity-0' : pending || !visible || size == 'xxs' }"
      :style="`color: ${ FGColor() };`"
    >{{ content?.firstname?.[0] ?? '?' }}{{ content?.lastname?.[0] ?? '?' }}</p>
  </div>
</div>
</template>

<script lang="ts">

import { HexToHSL } from "@/utilities"

export default {
  props: {
    size: {
      type: String,
      default: "md", // xxs, xs, sm, md, lg, xl, 2xl
    },
    content: {
      type: Object,
      default: { firstname: "", lastname : "", color: "#ef5a3c" },
    },
    pending: {
      type: Boolean,
      default: false,
    },
    visible : {
      type: Boolean,
      default: true,
    }
  },
  setup(props) {

    function BGColor(brightness = 80){
      const hsl = HexToHSL(props.content?.color ?? '#ef5a3c');
      return `hsl(${hsl.h}, ${hsl.s * 1}%, ${brightness * 1.05}%)`;
    }

    function FGColor(alpha= 1){
      const hsl = HexToHSL(props.content?.color ?? '#ef5a3c');
      return `hsl(${hsl.h}, ${hsl.s * 0.75}%, ${hsl.l * 0.8}%, ${alpha})`;
    }

    return {
      BGColor,
      FGColor,
    }
  },
}
</script>